export const host = typeof window !== 'undefined' ? window.location.hostname : null

export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

export function capitalise(string = '') {
  return string && string[0].toUpperCase() + string.slice(1)
}

export function join(delimiter = ' ', ...args) {
  return args.join(delimiter).trim()
}

export function sluggify(string = '') {
  return (
    string &&
    [...string.toLowerCase()]
      .filter((char) => /[\w\d\s-/~]/.test(char))
      .join('')
      .replace(/[\s-/~]/g, '-')
  )
}

export function secondsToTime(time) {
  const secs = time > 0 ? time % 60 : 0
  const mins = time > 0 ? (time - (time % 60)) / 60 : 0

  return `${mins > 9 ? mins : '0' + mins}:${secs > 9 ? secs : '0' + secs}`
}

export function timeToSeconds(time) {
  const [minutes, seconds] = time.split(':')

  return parseInt(minutes) * 60 + parseInt(seconds)
}

export function timeComponentFormatted(part) {
  const time = parseInt(part, 10)
  return `${time > 9 ? time : '0' + time}`
}

export function toQueryString(params) {
  return Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&')
}

export function validateEmailAddress(email = '') {
  const regex =
    /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]{1,64}@(?:[a-zA-Z0-9-]{1,63}[.]{1}){1,125}[a-zA-Z0-9]{2,63}$/

  return email && regex.test(email)
}

function sortSequence(data) {
  const sortedData = []
  const dataMap = new Map(data.map((item) => [item.id, item]))

  let currentId = null
  let currentEvent = data.find((event) => !event.cause)

  while (currentEvent) {
    sortedData.unshift(currentEvent)
    currentId = currentEvent.effect
    currentEvent = currentId ? dataMap.get(currentId) : null
  }

  return sortedData
}

export const processEvents = (events) => {
  let sequenceId = 1
  const eventsWithMeta = events.map((event) => ({
    ...event,
    sequenceId: null,
    isPartOfSequence: false,
  }))

  const eventMap = new Map(eventsWithMeta.map((event) => [event.id, event]))

  // Helper function to find and mark the sequence, starting from an event
  function markSequenceFromEvent(currentEvent, currentSequenceId) {
    while (currentEvent) {
      // Avoid re-marking an event that's already part of this sequence
      if (currentEvent.sequenceId === currentSequenceId) {
        break
      }

      currentEvent.sequenceId = currentSequenceId
      currentEvent.isPartOfSequence = true

      // Move to the next event in the sequence based on 'effect', if present
      if (currentEvent.effect) {
        currentEvent = eventMap.get(currentEvent.effect)
      } else {
        // End of the sequence
        break
      }
    }
  }

  // Assign provisional sequence IDs based on direct sequence chains
  eventsWithMeta.forEach((event) => {
    if (!event.cause && event.effect) {
      // Start of a sequence found
      markSequenceFromEvent(event, sequenceId++)
    }
  })

  // Ensure all events that are part of sequences are correctly identified
  eventsWithMeta.forEach((event) => {
    if (event.cause && !event.sequenceId) {
      const causeEvent = eventMap.get(event.cause)
      if (causeEvent && causeEvent.sequenceId) {
        markSequenceFromEvent(event, causeEvent.sequenceId)
      }
    }
  })

  // Map events by their sequence ID for sorting within sequences
  const sequences = new Map()
  eventsWithMeta.forEach((event) => {
    if (event.sequenceId) {
      if (!sequences.has(event.sequenceId)) {
        sequences.set(event.sequenceId, [])
      }
      sequences.get(event.sequenceId).push(event)
    }
  })
  // Sort events within each sequence based on cause-effect chains
  sequences.forEach((seqEvents, seqId) => {
    // Sort each sequence
    sequences.set(seqId, sortSequence(seqEvents))
  })
  // Flatten the sorted sequences back into a single array
  const sortedSequences = Array.from(sequences.values()).flat()

  // Separate events that are not part of any sequence
  const standaloneEvents = eventsWithMeta.filter((event) => !event.sequenceId)

  // Combine the sequences with standalone events
  const combinedEvents = sortedSequences.concat(standaloneEvents)

  // Final sort to place sequences and standalone events in the correct order
  const sortedEvents = combinedEvents.sort((a, b) => {
    // Compare sequence IDs if both events have them
    if (a.sequenceId && b.sequenceId) {
      return a.sequenceId - b.sequenceId
    } else if (a.sequenceId || b.sequenceId) {
      // Ensure that events with a sequenceId come before standalone events
      return a.sequenceId ? -1 : 1
    }
    // If both events are standalone, sort by timestamp
    return a.timestamp - b.timestamp
  })

  return sortedEvents
}
