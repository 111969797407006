import { call, takeLatest, put } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import api, { apiV2 } from 'services/api'
import * as gameActions from 'redux/actions/game'

function* autoCompleteTags(action) {
  try {
    const { data } = yield call(api.get, `/tag/autocomplete`, {
      params: {
        query: action.query,
        template_id: action.templateId,
      },
    })

    yield put({ type: gameActions.RECEIVE_AUTOCOMPLETE_TAGS, data })
  } catch (error) {
    yield put({ type: gameActions.FAILED_AUTOCOMPLETE_TAGS })
  }
}

function* deleteGameEvent({ eventId, gameId }) {
  try {
    yield call(api.delete, `/game/${gameId}/event/${eventId}`)
    const sequenceKey = `game_${gameId}_sequence`
    // Retrieve the current sequence from session storage
    const sequenceData = sessionStorage.getItem(sequenceKey)
    let sequence = sequenceData ? JSON.parse(sequenceData) : { events: [] }
    // find previous and next of deleted event and update neighbors
    const myIndex = sequence.events.findIndex((event) => event.eventId === eventId)
    const nextEvent = sequence.events[myIndex - 1]
    const previousEvent = sequence.events[myIndex + 1]

    if (nextEvent) {
      // UPDATE CAUSE TO NEXT EVENT
      yield call(apiV2.put, `/game/cause/effect/${nextEvent.eventId}`, {
        cause: previousEvent ? previousEvent?.eventId : null,
      })
    }
    if (previousEvent) {
      // UPDAT EFFECT TO PREV EVENT
      yield call(apiV2.put, `/game/cause/effect/${previousEvent.eventId}`, {
        effect: nextEvent ? nextEvent?.eventId : null,
      })
    }

    yield put({ type: gameActions.RECEIVE_DELETE_GAME_EVENT })
    yield put({ type: gameActions.REQUEST_GET_GAME_EVENTS, id: gameId })
  } catch (error) {
    yield put({ type: gameActions.FAILED_DELETE_GAME_EVENT })
  }
}

function* getGame(action) {
  try {
    const data = yield call(api.get, `/game/${action.id}`)

    yield put({ type: gameActions.RECEIVE_GET_GAME, data })
  } catch (error) {
    yield put({ type: gameActions.FAILED_GET_GAME })
  }
}

function* getGameClock(action) {
  try {
    const { data } = yield call(api.get, `/game/${action.id}/clock`)

    yield put({ type: gameActions.RECEIVE_GET_GAME_CLOCK, data })
  } catch (error) {
    yield put({ type: gameActions.FAILED_GET_GAME_CLOCK })
  }
}

function* getGameEvents(action) {
  try {
    const { data } = yield call(api.get, `/game/${action.id}/event`)

    yield put({ type: gameActions.RECEIVE_GET_GAME_EVENTS, data })
  } catch (error) {
    yield put({ type: gameActions.FAILED_GET_GAME_EVENTS })
  }
}

function* getGameScore(action) {
  try {
    const { data } = yield call(api.get, `/game/${action.id}/score`)

    yield put({ type: gameActions.RECEIVE_GET_GAME_SCORE, data })
  } catch (error) {
    yield put({ type: gameActions.FAILED_GET_GAME_SCORE })
  }
}

function* getGames(action) {
  try {
    const { phase, include } = action.params
    const queryParams = {
      ...(include ? { include } : {}),
      ...(phase ? { phase } : {}),
    }
    const queryString = Object.keys(queryParams)
      .map((param) => `${param}=${queryParams[param]}`)
      .join('&')
    const endpoint = queryString ? `/game?${queryString}` : `/game`
    const { data } = yield call(apiV2.get, endpoint)

    yield put({ type: gameActions.RECEIVE_GET_GAMES, data })
  } catch (error) {
    yield put({ type: gameActions.FAILED_GET_GAMES })
  }
}

function* persistGameRound(action) {
  try {
    const data = yield call(apiV2.put, `/game/${action.id}/assignRound`, action.data)

    yield put({ type: gameActions.RECEIVE_PERSIST_GAME_ROUND, data })

    yield put({
      type: gameActions.REQUEST_GET_GAMES,
      params: action.callbackParams,
    })
  } catch (error) {
    yield put({ type: gameActions.FAILED_PERSIST_GAME_ROUND })
  }
}

function* persistGameEvent(action = {}) {
  try {
    const { currentSequence = {}, setCurrentSequence } = action
    const sharedPayload = {
      game_id: action.id,
      timestamp: action.event.timestamp,
      team_id: action.event.teamId,
      player_id: action.event.playerId,
      coordinates: action.event.coordinates,
    }
    
    const payload = {
      template_id: action.event.selectedTemplate.id,
      ...sharedPayload,
      ...(action.event.disableTimestampPeriodOffset
        ? { disable_timestamp_period_offset: true }
        : {}),
    }
    if (action.event.id) {
      yield call(apiV2.put, `/game/${action.id}/event/${action.event.id}`, payload)
    } else {
      const referenceIndex = currentSequence?.events?.findIndex((event) => event.eventId === null) || 0

      if (currentSequence.sequencePreview) {
        payload.effect = !!currentSequence.events.length ? currentSequence.events[referenceIndex - 1]?.eventId : null
        payload.cause = !!currentSequence.events.length ? currentSequence.events[referenceIndex + 1]?.eventId : null
      } else if (referenceIndex !== 0) {
        payload.effect = null
        payload.cause = !!currentSequence.events.length
          ? currentSequence.events[currentSequence.events.length - 2]?.eventId
          : null
      }

      const createdEvent = yield call(apiV2.post, `/game/${action.id}/event`, payload)

      if (referenceIndex !== 0 && currentSequence.sequencePreview) {
        const previousEvent = !!currentSequence.events.length ? currentSequence.events[referenceIndex + 1] : null
        const nextEvent = !!currentSequence.events.length ? currentSequence.events[referenceIndex - 1] : null

        if (nextEvent) {
          // UPDATE CAUSE TO NEXT EVENT
          yield call(apiV2.put, `/game/cause/effect/${nextEvent.eventId}`, {
            cause: createdEvent?.uuid,
          })
        }
        if (previousEvent) {
          // UPDAT EFFECT TO PREV EVENT
          yield call(apiV2.put, `/game/cause/effect/${previousEvent.eventId}`, {
            effect: createdEvent?.uuid,
          })
        }
      }

      const eventSequence = createdEvent?.sequence

      // Update sequence state if template starts or is in a sequence
      if (['start','in'].includes(eventSequence)) {
        if (typeof setCurrentSequence === 'function') {
          const updatedSequence = {
            ...currentSequence,
            events: currentSequence?.events?.length > 0
              ? currentSequence?.events?.map((event, index) => index === referenceIndex 
                ? ({ ...event, eventId: createdEvent.uuid }) 
                : event
              )
              : [{
                eventId: createdEvent.uuid,
                name: action.event.selectedTemplate.label,
                sequenceId: 1,
                templateId: action.event.selectedTemplate.id,
              }]
          }

          setCurrentSequence(updatedSequence)
        }
      } else if (eventSequence === 'end') {
        if (typeof setCurrentSequence === 'function') {
          setCurrentSequence(undefined)
        }
      }
    }

    yield put({ type: gameActions.RECEIVE_PERSIST_GAME_EVENT })

    switch (action.event.selectedTemplate.type) {
      case 'clock':
        yield put({ type: gameActions.REQUEST_GET_GAME_CLOCK, id: action.id })
        break
      case 'score':
        yield put({ type: gameActions.REQUEST_GET_GAME_SCORE, id: action.id })
        break
    }

    yield put({ type: gameActions.REQUEST_GET_GAME_EVENTS, id: action.id })
  } catch (error) {
    yield put({ type: gameActions.FAILED_PERSIST_GAME_EVENT })
  }
}

function* toggleGameClock(action) {
  try {
    const { data } = yield call(api.put, `/game/${action.id}/clock/toggle`, {
      timestamp: action.timestamp,
    })

    yield put({ type: gameActions.RECEIVE_TOGGLE_GAME_CLOCK, data })
  } catch (error) {
    yield put({ type: gameActions.FAILED_TOGGLE_GAME_CLOCK })
  }
}

function* updateGamePeriod(action) {
  try {
    const { data } = yield call(api.put, `/game/${action.id}/clock/period`, {
      period: action.period,
      timestamp: action.timestamp,
    })

    yield put({ type: gameActions.RECEIVE_UPDATE_GAME_PERIOD, data })
  } catch (error) {
    yield put({ type: gameActions.FAILED_UPDATE_GAME_PERIOD })
  }
}

export default function* root() {
  yield takeLatest(gameActions.REQUEST_AUTOCOMPLETE_TAGS, autoCompleteTags)
  yield takeLatest(gameActions.REQUEST_DELETE_GAME_EVENT, deleteGameEvent)
  yield takeLatest(gameActions.REQUEST_GET_GAME, getGame)
  yield takeLatest(gameActions.REQUEST_GET_GAME_CLOCK, getGameClock)
  yield takeLatest(gameActions.REQUEST_GET_GAME_EVENTS, getGameEvents)
  yield takeLatest(gameActions.REQUEST_GET_GAME_SCORE, getGameScore)
  yield takeLatest(gameActions.REQUEST_GET_GAMES, getGames)
  yield takeLatest(gameActions.REQUEST_PERSIST_GAME_EVENT, persistGameEvent)
  yield takeLatest(gameActions.REQUEST_PERSIST_GAME_ROUND, persistGameRound)
  yield takeLatest(gameActions.REQUEST_TOGGLE_GAME_CLOCK, toggleGameClock)
  yield takeLatest(gameActions.REQUEST_UPDATE_GAME_PERIOD, updateGamePeriod)
}
